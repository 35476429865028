import Vue from "vue";
import ReportFornitoreItem from "../../../models/reportFornitori/ReportFornitoreItem";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import { ReportFornitoriModel } from "@/models/reportFornitori/ReportFornitoriModel";
import { filterBy } from "@progress/kendo-data-query";
import helpers from "@/helpers";
import { ReportSendType } from "@/models/reportFornitori/ReportSendType";
import { DayOfWeek } from "@/models/dayOfWeek";
import { SpecialDayOfMonth } from "@/models/reportFornitori/SpecialDayOfMonth";
import SchedulingItem from "@/models/reportFornitori/SchedulingItem";
import { FileInvioOnLine } from "@/models/reportFornitori/FileInvioOnLine";
import { ExportReportItem } from "@/models/reportFornitori/exportReportItem";
import { ReportFornitoreExportItem } from "../../../models/reportFornitori/ReportFornitoreExportItem";
import permessi from "@/config/permessi";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import FilterTypeItem from "@/models/reportFornitori/filterTypeItem";
import ReportFornitoreFilterItem from "../../../models/reportFornitori/ReportFornitoreFilterItem";
import { ReportFornitoreFilter } from "./reportFornitoreFilter";

@Component({})
export default class ReportFornitorePage extends baseUserContextComponent {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.REPORTFORNITORE];
	}
	reportFornitori: ReportFornitoreItem[] = [];

	isInEditMode: boolean = false;
	visible: any[] = [{ show: true }];

	pacchetti: TextValueItem[] = [];
	prodotti: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	contraenti: TextValueItem[] = [];
	contratti: TextValueItem[] = [];
	distributori: TextValueItem[] = [];
	pacchettiFiltered: TextValueItem[] = [];
	prodottiFiltered: TextValueItem[] = [];
	fornitoriFiltered: TextValueItem[] = [];
	contraentiFiltered: TextValueItem[] = [];
	contrattiFiltered: string[] = [];
	distributoriFiltered: TextValueItem[] = [];
	fileInvioOnLineItems: TextValueItem[] = [];
	fileInvioOnLineItemsFiltered: TextValueItem[] = [];

	exportModels: ReportFornitoreExportItem[] = [];

	reportModels: TextValueItem[] = [];
	reportModelsFiltered: TextValueItem[] = [];

	sendTypes: string[] = [];
	sendTypesFiltered: string[] = [];

	daysOfWeekArray: TextValueItem[] = [];
	specialDayOfMonthItems: TextValueItem[] = [];
	// selectedTime: string = "";

	showModalActivateReport: boolean = false;
	repToActivate: ReportFornitoreItem = null;

	showModalDeleteReport: boolean = false;
	repToDelete: ReportFornitoreItem = null;

	showModalExecuteReport: boolean = false;
	showModalExecuteReportUseTime: boolean = false;
	isScheduleJob: boolean = true;
	dataDa: Date = null;
	dataA: Date = null;
	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";

	filtersType: FilterTypeItem[] = [];
	filterSelected: FilterTypeItem = null;

	showOnlyActive: boolean = true;

	created() {
		// this.reportModels = Object.keys(ReportFornitoriModel);
		this.reportModelsFiltered = this.reportModels;

		this.filtersType = [
			{ value: 0, text: this.$i18n.t("reportfornitori.prodotti").toString(), variant: "primary", textVariant: "white" },
			{ value: 1, text: this.$i18n.t("reportfornitori.polizze").toString(), variant: "secondary", textVariant: "white" },
			{ value: 2, text: this.$i18n.t("reportfornitori.fornitori").toString(), variant: "success", textVariant: "white" },
			{ value: 3, text: this.$i18n.t("reportfornitori.contraenti").toString(), variant: "info", textVariant: "white" },
			{ value: 4, text: this.$i18n.t("reportfornitori.contratti").toString(), variant: "warning", textVariant: "white" },
			{ value: 5, text: this.$i18n.t("reportfornitori.distributori").toString(), variant: "danger", textVariant: "white" },
			{ value: 6, text: this.$i18n.t("reportfornitori.fileInvioOnLine").toString(), variant: "danger", textVariant: "white" }
		];

		this.filterSelected = this.filtersType[0];

		this.sendTypes = Object.keys(ReportSendType);
		this.sendTypesFiltered = this.sendTypes;

		let daysOfWeek: string[] = Object.values(DayOfWeek);
		for (var i = 0; i < daysOfWeek.length; i++) {
			let day = daysOfWeek[i];
			let d: TextValueItem = { value: day, text: this.$i18n.t(helpers.GetDayNameByWeekDay(day)).toString() };
			this.daysOfWeekArray.push(d);
		}

		let specialDayOfMonthItemsTmp: string[] = Object.keys(SpecialDayOfMonth);
		for (var i = 0; i < specialDayOfMonthItemsTmp.length; i++) {
			let dItem = specialDayOfMonthItemsTmp[i];
			let d: TextValueItem = { value: dItem, text: this.$i18n.t(SpecialDayOfMonth[dItem].toString()).toString() };
			this.specialDayOfMonthItems.push(d);
		}

		// let fileInvioOnLine: string[] = Object.values(FileInvioOnLine);
		// for (var i = 0; i < fileInvioOnLine.length; i++) {
		// 	let day = fileInvioOnLine[i];
		// 	let d: TextValueItem = { value: day, text: this.$i18n.t(helpers.GetDayNameByWeekDay(day)).toString() };
		// 	this.fileInvioOnLineItems.push(d);
		// }

		var self = this;
		var distributori = this.getDistributoriDropDown();
		var pacchetti = this.getPacchettiDropDown();
		var prodotti = this.getProdottiDropDown();
		var fornitori = this.getFornitoriDropDown();
		var contraenti = this.getContraentiDropDown();
		var contratti = this.getContrattiDropDown();
		var fornitoriFileInvio = this.getFornitoriFileInvioDropDown();
		var exportModels = this.getReportFornitoriExportModel();

		Promise.all([distributori, pacchetti, prodotti, fornitori, contraenti, contratti, fornitoriFileInvio, exportModels])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getPacchettiDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI)
			.then(res => {
				self.pacchetti = res;
				self.pacchettiFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getProdottiDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.PRODOTTI)
			.then(res => {
				self.prodotti = res;
				self.prodottiFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getFornitoriDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI)
			.then(res => {
				self.fornitori = res;
				self.fornitoriFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getContraentiDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.CONTRAENTI)
			.then(res => {
				self.contraenti = res;
				self.contraentiFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getDistributoriDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI)
			.then(res => {
				self.distributori = res;
				self.distributoriFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getContrattiDropDown() {
		var self = this;
		return api
			.getDatiDropDownAsString(api.endpoint.DROPDOWN.CONTRATTI)
			.then(res => {
				self.contratti = res.filter(function(item){
					if(item)
						return true;
				}).map(function(item) {
					return {
						value: item,
						text: item
					};
				});
				//self.contrattiFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getFornitoriFileInvioDropDown() {
		var self = this;
		return api
			.getDatiDropDownAsString(api.endpoint.DROPDOWN.FORNITORIFILEINVIO)
			.then(res => {
				self.fileInvioOnLineItems = res.map(function(item) {
					return {
						value: item,
						text: item
					};
				});
				//self.fileInvioOnLineItemsFiltered = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getReportFornitoriExportModel() {
		var self = this;
		return api
			.getReportFornitoriExportModel()
			.then(res => {
				self.exportModels = res;
				self.reportModels = res.map(function(item) {
					return {
						value: item.itemID,
						text: item.name,
						code: null,
						description: null
					};
				});

				self.reportModelsFiltered = self.reportModels;
			})
			.catch(err => {
				console.log(err);
			});
	}

	annullaSceltaGiorniMese(rep) {
		if (rep.scheduling && rep.scheduling.specialDaysOfMonth) {
			rep.scheduling.specialDaysOfMonth = null;
		}
	}

	getData() {
		var self = this;
		api.getReportFornitori()
			.then(res => {
				self.reportFornitori = res;
				self.reportFornitori.forEach(item => {
					Vue.set(item, "isInEditMode", false);
					Vue.set(item, "selectedTime", "");

					// if (item.scheduling && item.scheduling.specialDaysOfMonth) {
					// 	debugger;
					// 	Vue.set(item, "item.scheduling.specialDaysOfMonth", item.scheduling.specialDaysOfMonth);
					// }
				});

				self.visible = [];
				for (var i = 0; i < res.length; i++) {
					self.visible.push({ show: false });
				}
			})
			.catch(err => {});
	}

	changeVisibilityPanel(index: number) {
		this.visible[index].show = !this.visible[index].show;
	}

	onAddNewReport() {
		let newReport = new ReportFornitoreItem();
		newReport.exportModel = this.reportModels[0].value as number;
		this.visible.unshift({ show: true });
		this.reportFornitori.unshift(newReport);
	}

	getSelectedExportModelValue(itemId: number) {
		return this.reportModels.find(function(item) {
			return item.value == itemId;
		});
	}

	onFilterModelReportChange(event: any) {
		this.reportModelsFiltered = this.filterDataObject(this.reportModels, event.filter);
	}

	/*
	onFilterFileInvioOnlineChange(event: any) {
		this.fileInvioOnLineItemsFiltered = this.filterData(this.fileInvioOnLineItems, event.filter);
	}

	onReportSendTypeChange(event: any) {
		this.sendTypesFiltered = this.filterData(this.sendTypes, event.filter);
	}

	onFilterPacchettiFilteredChange(event: any) {
		this.pacchettiFiltered = this.filterDataObject(this.pacchetti, event.filter);
	}
	onFilterProdottiFilteredChange(event: any) {
		this.prodottiFiltered = this.filterDataObject(this.prodotti, event.filter);
	}
	onFilterFornitoriFilteredChange(event: any) {
		this.fornitoriFiltered = this.filterDataObject(this.fornitori, event.filter);
	}
	onFilterContraentiFilteredChange(event: any) {
		this.contraentiFiltered = this.filterDataObject(this.contraenti, event.filter);
	}
	onFilterContrattiFilteredChange(event: any) {
		this.contrattiFiltered = this.filterData(this.contratti, event.filter);
	}
	onFilterDistributoriFilteredChange(event: any) {
		this.distributoriFiltered = this.filterDataObject(this.distributori, event.filter);
	}

	onDistributoriChange(event: any, rep: ReportFornitoreItem) {
		if (event && event.value)
			rep.filters.distributori = event.value.map(function(item) {
				return item.value;
			});
	}

	onPacchettiChange(event: any, rep: ReportFornitoreItem) {
		if (event && event.value)
			rep.filters.pacchetti = event.value.map(function(item) {
				return item.value;
			});
	}

	onProdottiChange(event: any, rep: ReportFornitoreItem) {
		if (event && event.value)
			rep.filters.prodotti = event.value.map(function(item) {
				return item.value;
			});
	}

	onFornitoriChange(event: any, rep: ReportFornitoreItem) {
		if (event && event.value)
			rep.filters.fornitori = event.value.map(function(item) {
				return item.value;
			});
	}

	onContraentiChange(event: any, rep: ReportFornitoreItem) {
		if (event && event.value)
			rep.filters.contraenti = event.value.map(function(item) {
				return item.value;
			});
	}

	*/
	// onContrattiChange(event: any, rep: ReportFornitoreItem) {
	// 	if (event && event.value)
	// 		rep.filters.contratti = event.value.map(function(item) {
	// 			return item.value;
	// 		});
	// }

	onExportModelChange(event: any, rep: ReportFornitoreItem) {
		if (event && event.value) rep.exportModel = event.value.value;
	}

	onClauseChange(newClause, filter){
		filter.clause = newClause;
	}
	getMultiselectValueFromData(searchItems: number[], listData: TextValueItem[]) {
		if (searchItems) {
			return listData.filter(function(item) {
				return searchItems.indexOf(item.value as number) >= 0;
			});
		}
		return [];
	}

	filterDataObject(items: TextValueItem[], filter): TextValueItem[] {
		const data = items;
		return filterBy(data, filter);
	}

	filterData(items: string[], filter): string[] {
		const data = items.slice();
		return filterBy(data, filter);
	}

	removeAtIndex(rep: ReportFornitoreItem, index: number) {
		if (index >= 0 && rep.isInEditMode) {
			rep.scheduling.executeTimes.splice(index, 1);
		}
	}

	addExecuteHour(rep: ReportFornitoreItem) {
		var re = new RegExp("^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])|(([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9])$");
		if (rep.selectedTime && re.test(rep.selectedTime)) {
			let time = rep.selectedTime;
			let timeSpl = time.split(":");
			if (timeSpl.length > 2) {
				time = `${timeSpl[0]}:${timeSpl[1]}`;
			}
			if (rep.scheduling.executeTimes.indexOf(time) >= 0) {
				this.$bvToast.toast("orario già presente", {
					variant: "danger",
					title: "ATTENZIONE",
					solid: true
				});

				return null;
			}

			rep.scheduling.executeTimes.push(time);
		} else {
			this.$bvToast.toast("orario non valido", {
				variant: "danger",
				title: "ATTENZIONE",
				solid: true
			});
		}
	}

	onEditModeChange(rep: ReportFornitoreItem) {
		rep.isInEditMode = !rep.isInEditMode;
	}

	cancel(rep: ReportFornitoreItem, index: number) {
		rep.isInEditMode = false;
		var self = this;
		api.getReportFornitoriById(rep.itemID)
			.then(res => {
				Vue.set(self.reportFornitori, index, res);
				Vue.set(self.reportFornitori[index], "isInEditMode", false);
				Vue.set(self.reportFornitori[index], "selectedTime", "");
			})
			.catch(err => {});
	}

	onSaveEdit(rep: ReportFornitoreItem) {
		var self = this;
		if (rep.itemID <= 0) {
			api.newReportFornitore(rep)
				.then(res => {
					self.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.reportfornitore") }).toString(), {
						variant: "success",
						title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
						solid: true
					});

					rep.isInEditMode = false;
					rep.itemID = res.data;
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			api.updateReportFornitore(rep)
				.then(res => {
					self.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.reportfornitore") }).toString(), {
						variant: "success",
						title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
						solid: true
					});

					rep.isInEditMode = false;
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	onReportActiveChange(rep: ReportFornitoreItem) {
		this.repToActivate = rep;
		this.showModalActivateReport = true;
	}

	attivazioneReport() {
		var self = this;
		api.activateReportFornitore(this.repToActivate.itemID)
			.then(res => {
				self.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.reportfornitore") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				this.repToActivate.isActive = res.data;
				this.repToActivate = null;
				this.showModalActivateReport = false;
			})
			.catch(err => {
				self.$bvToast.toast(self.$i18n.t("generico.erroreGenerico").toString(), {
					variant: "error",
					title: self.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});
	}

	annullaAttivazioneReport() {
		// let chkref = `chkActive_${this.indexRepToActive}`;
		// let chk = this.$refs[`${chkref}`] as any;
		// chk[0].$el.click();
		this.repToActivate.isActive = !this.repToActivate.isActive;
		this.repToActivate = null;
		this.showModalActivateReport = false;
	}

	onExportReportAction(rep: ReportFornitoreItem) {
		this.repToActivate = rep;
		this.showModalExecuteReport = true;
		this.showModalExecuteReportUseTime = rep.scheduling.useTime ? true : false;
		this.isScheduleJob = true;
	}

	exportReportAction() {
		var self = this;
		var data: ExportReportItem = {
			itemId: this.repToActivate.itemID,
			dataDa: this.dataDa,
			dataA: this.dataA,
			isScheduleJob: this.isScheduleJob
		};
		api.executeExportReportFornitore(data)
			.then(res => {
				self.$bvToast.toast(this.$i18n.t("generico.creazioneInvioReportOk").toString(), {
					variant: "success",
					title: this.$i18n.t("msg.invioReport").toString(),
					solid: true
				});

				this.repToActivate = null;
				this.showModalExecuteReport = false;
				this.showModalExecuteReportUseTime = false;
				this.dataDa = null;
				this.dataA = null;
			})
			.catch(err => {
				self.$bvToast.toast(self.$i18n.t("generico.erroreGenerico").toString(), {
					variant: "error",
					title: self.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});
	}

	annullaExportReportAction() {
		this.repToActivate = null;
		this.showModalExecuteReport = false;
		this.showModalExecuteReportUseTime = false;
		this.dataDa = null;
		this.dataA = null;
	}

	showDeleteConfirm(rep: ReportFornitoreItem) {
		this.repToDelete = rep;
		this.showModalDeleteReport = true;
	}

	deleteReport() {
		var self = this;
		if (this.repToDelete.itemID > 0) {
			api.deleteReportFornitore(this.repToDelete.itemID)
				.then(res => {
					self.$bvToast.toast(self.$i18n.t("msg.cancellazioneriuscita").toString(), {
						variant: "success",
						title: self.$i18n.t("msg.cancellazioneok").toString(),
						solid: true
					});

					let index = self.reportFornitori.indexOf(this.repToDelete);
					if (index >= 0) {
						self._.pullAt(self.reportFornitori, index);
						self._.pullAt(self.visible, index);
					}

					self.repToDelete = null;
					self.showModalDeleteReport = false;
				})
				.catch(err => {
					self.$bvToast.toast(self.$i18n.t("msg.cancellazionenonriuscita").toString(), {
						variant: "success",
						title: self.$i18n.t("msg.cancellazioneko").toString(),
						solid: true
					});
				});
		} else {
			let index = this.reportFornitori.indexOf(this.repToDelete);
			if (index >= 0) {
				this._.pullAt(this.reportFornitori, index);
				this._.pullAt(this.visible, index);
			}

			this.repToDelete = null;
			this.showModalDeleteReport = false;
		}
	}

	annullaDeleteReportAction() {
		this.repToDelete = null;
		this.showModalDeleteReport = false;
	}

	getColumnsDefinitionForGrid( rep: ReportFornitoreItem) {
		var colDef = this.getColumnsDefinitionByModel(rep.exportModel);
		
		let model = this.exportModels.find(function(item) {
			return item.itemID == rep.exportModel;
		});

		if( model.generatedFileType === 'genera_default_filtri'){
			return colDef.filter( (obj) => {
				return ( rep.columns || [] ).indexOf( obj.field) >= 0;
			});
		}
		else
			return colDef;
	}

	getColumnsDefinitionByModel(exportModelId: number) {
		let model = this.exportModels.find(function(item) {
			return item.itemID == exportModelId;
		});

		if (model) {
			let columnsDef = model.columnsDefinition;
			return JSON.parse(`${columnsDef}`);
		}

		return [];
	}

	getExportModelItem(exportModelId: number) {
		let model = this.exportModels.find(function(item) {
			return item.itemID == exportModelId;
		});

		return model || {};
	}

	addNewFilter(rep: ReportFornitoreItem) {
		if (rep.isInEditMode && this.filterSelected) {
			let reportFornitoreFilter = new ReportFornitoreFilter();
			reportFornitoreFilter.filterType = this.filterSelected.value;
			reportFornitoreFilter.variant = this.filterSelected.variant;
			reportFornitoreFilter.textVariant = this.filterSelected.textVariant;

			if (!rep.filterItems) {
				rep.filterItems = new ReportFornitoreFilterItem();
			}

			rep.filterItems.filters.push(reportFornitoreFilter);
		} else {
		}
	}

	removeFilter(rep: ReportFornitoreItem, index: number) {
		if(rep.isInEditMode)
			rep.filterItems.filters.splice(index, 1);
	}

	getDataFromFiltrerType(fType: number) {
		switch (fType) {
			case 0:
				return this.pacchetti;
				break;
			case 1:
				return this.prodotti;
				break;
			case 2:
				return this.fornitori;
				break;
			case 3:
				return this.contraenti;
				break;
			case 4:
				return this.contratti;
				break;
			case 5:
				return this.distributori;
				break;
			case 6:
				return this.fileInvioOnLineItems;
				break;
			default:
				break;
		}
	}

	aggiungiTutteColonne(rep: ReportFornitoreItem){
		var allCols = this.getColumnsFiltersSchema(rep.exportModel);
		rep.columns = allCols ? allCols.map(obj => obj.value) as [] : [];
	}

	rimuoviTutteColonne(rep: ReportFornitoreItem){
		rep.columns = []
	}

	getColumnsFiltersSchema(exportModelId: number ){
		var colDef = this.getColumnsDefinitionByModel(exportModelId);

		var ds = [] as TextValueItem[];
		if( colDef && colDef.length){
			var ds = [] as TextValueItem[];
			for(var i = 0; i < colDef.length; i++){
				ds.push({
					value: colDef[i].field,
					text: colDef[i].title
				})
			}
		}
		return ds;
	}

	onChangeColonneSchema(ev, rep){

	}
}
