import { render, staticRenderFns } from "./modificaPraticaECommerce.vue?vue&type=template&id=41d61f20&scoped=true&"
import script from "./modificaPraticaECommerce.vue.ts?vue&type=script&lang=ts&"
export * from "./modificaPraticaECommerce.vue.ts?vue&type=script&lang=ts&"
import style0 from "./modificaPraticaECommerce.vue?vue&type=style&index=0&id=41d61f20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41d61f20",
  null
  
)

export default component.exports