import { render, staticRenderFns } from "./ricercaGaranzieCoupon.vue?vue&type=template&id=6bc44f1e&"
import script from "./ricercaGaranzieCoupon.vue.ts?vue&type=script&lang=ts&"
export * from "./ricercaGaranzieCoupon.vue.ts?vue&type=script&lang=ts&"
import style0 from "./ricercaGaranzieCoupon.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports